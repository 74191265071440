import { useEffect } from 'react';
import styled from 'styled-components';
import GlobalSearch from 'src/modules/search/shared/components/searchBar';
import i18n from 'src/utils/translate';
import { trackFootfallEvent } from 'src/utils/footfall';
import { Event } from 'src/constants/footfall';
import { titleLarge, titleMedium } from 'styles-js/mixins/typography';
export default function ErrorPage({
  status = 404
}: {
  status?: number;
}) {
  useEffect(() => {
    trackFootfallEvent(Event.SawOopsPage);
  }, []);
  const statuses: {
    [key: number]: string;
  } = {
    404: i18n.t(`Looks like that page can't be found!`),
    410: i18n.t('Looks like that page no longer exists!'),
    500: i18n.t('Looks like that page no longer exists!')
  };
  return <Wrapper data-testid="error-page" data-sentry-element="Wrapper" data-sentry-component="ErrorPage" data-sentry-source-file="error.tsx">
      <h1>{statuses[status] || statuses[404]}</h1>
      <h2>{i18n.t('Try a search')}</h2>
      <StyledSearchBar placeholder="Search HealthUnlocked" data-sentry-element="StyledSearchBar" data-sentry-source-file="error.tsx" />
    </Wrapper>;
}
const Wrapper = styled.div`
  box-sizing: border-box;
  padding: 60px;
  text-align: center;
  width: 100%;

  h1 {
    ${titleLarge}
  }

  h2 {
    ${titleMedium}
  }
`;
const StyledSearchBar = styled(GlobalSearch)`
  max-width: 550px;
  margin: 30px auto 0;
`;