// @ts-strict-ignore
import styled from 'styled-components';
import { useDispatch, useSelector } from 'src/store/store';
import i18n from 'src/utils/translate';
import { trackFootfallEvent } from 'src/utils/footfall';
import { Event, Section } from 'src/constants/footfall';
import { showModal } from 'src/modules/shared/modal/actions';
import { LinkButton } from 'styles-js/buttons';
import { ModalType } from 'src/constants/modalTypes';
import Avatar from 'src/components/avatar';
export default function WhatsYourQuestion({
  classes
}: {
  classes?: string;
}) {
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.context.currentUser);
  if (!currentUser) return null;
  return <Container className={classes} data-sentry-element="Container" data-sentry-component="WhatsYourQuestion" data-sentry-source-file="whatsYourQuestion.tsx">
      <Avatar name={currentUser.username} url={currentUser.avatarImageUrl} data-sentry-element="Avatar" data-sentry-source-file="whatsYourQuestion.tsx" />
      <StyledLinkButton data-testid="whats-your-question-button" onClick={() => {
      trackFootfallEvent(Event.Clicked, {
        clickType: 'clicked-write-a-post',
        clickMetadata: {
          section: Section.NewsFeedWritePost
        }
      });
      dispatch(showModal({
        modalType: ModalType.SelectCommunityToPost,
        modalProps: {
          section: Section.NewsFeedWritePost
        }
      }));
    }} data-sentry-element="StyledLinkButton" data-sentry-source-file="whatsYourQuestion.tsx">
        {i18n.t(`What's your question?`)}
      </StyledLinkButton>
    </Container>;
}
const Container = styled.div`
  padding: 15px;
  margin-bottom: 15px;
  border: ${({
  theme
}) => theme.greyLine};

  @media (${({
  theme
}) => theme.underScreenSmall}) {
    margin-bottom: 0;
    border: none;
    border-bottom: ${({
  theme
}) => theme.greyLine};
    background-color: ${({
  theme
}) => theme.colorWhite};
    z-index: 1;
  }
`;
const StyledLinkButton = styled(LinkButton)`
  margin-left: 10px;
  vertical-align: middle;
`;